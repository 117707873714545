import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { getDisplayImageObj } from "@vc-workspace/utils-helpers";
import { Typography } from "@vc-workspace/utils-typography";
import { shopListContainer, shopContainerStyles, shopBgImage, shopDetailsContainer, shopLogoContainer, prevNavStyles, nextNavStyles } from './homepage-shop-group.module.style';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { LaymanHomePageSectionTypes } from "@vc-workspace/utils-constants";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AmplitudeEventNames, sendAmplitudeData } from "@vc-workspace/utils-amplitude";
import { useState } from "react";
import Link from "next/link";
/* eslint-disable-next-line */

export function HomepageShopGroup(props) {
  var _useState = useState(false),
      showPrev = _useState[0],
      togglePrev = _useState[1];

  var onPrevClick = function onPrevClick() {
    sendAmplitudeData(AmplitudeEventNames.PREV_CLICKED, {
      source: LaymanHomePageSectionTypes.SHOP_GROUP,
      source_name: props.data.title
    });
  };

  var onNextClick = function onNextClick() {
    togglePrev(true);
    sendAmplitudeData(AmplitudeEventNames.NEXT_CLICKED, {
      source: LaymanHomePageSectionTypes.SHOP_GROUP,
      source_name: props.data.title
    });
  };

  return _jsxs("div", {
    className: props.context.isMobileView ? "pt-8" : "pt-16",
    children: [_jsx(Typography, {
      type: props.context.isMobileView ? "SubHeading1" : "Headline_H2",
      color: "prussian_blue",
      className: "page-cushion-container pb-4",
      extra_bold: true,
      children: props.data.title
    }), props.context.isMobileView ? _jsx("div", {
      css: shopListContainer,
      className: "page-cushion-container--left",
      children: props.data.data.shops.map(function (shop) {
        var displayBgImage = getDisplayImageObj(shop.storyImages[0], "2");
        var displayLogoImage = getDisplayImageObj(shop.logo[0], "1");
        return _jsx(Link, {
          href: "/".concat(shop.slug, "?source=").concat(LaymanHomePageSectionTypes.SHOP_GROUP, "&source_name=").concat(props.data.title),
          passHref: true,
          children: _jsxs("div", {
            css: shopContainerStyles,
            children: [_jsxs("picture", {
              children: [_jsx("source", {
                srcSet: displayBgImage.webP,
                type: "image/webp"
              }), _jsx("img", {
                src: displayBgImage.url,
                css: shopBgImage
              })]
            }), _jsxs("div", {
              css: shopDetailsContainer,
              children: [_jsxs("div", {
                className: "flex items-center",
                children: [displayLogoImage.url ? _jsx("div", {
                  css: shopLogoContainer,
                  children: _jsxs("picture", {
                    children: [_jsx("source", {
                      srcSet: displayLogoImage.webP,
                      type: "image/webp"
                    }), _jsx("img", {
                      src: displayLogoImage.url,
                      css: shopBgImage
                    })]
                  })
                }) : null, _jsx(Typography, {
                  type: "SubHeading1",
                  color: "white",
                  bold: true,
                  children: shop.name
                })]
              }), _jsx(Typography, {
                type: "Body1",
                color: "white",
                className: "py-3 whitespace-normal",
                children: shop.storyText && shop.storyText.slice(0, 200)
              }), _jsxs(Typography, {
                type: "Body1",
                color: "laser_lemon",
                semi_bold: true,
                children: ["Shop now ", _jsx(ChevronRightRoundedIcon, {
                  fontSize: "small"
                })]
              })]
            })]
          })
        }, shop.slug);
      })
    }) : _jsx("div", {
      className: "page-cushion-container",
      children: _jsxs(CarouselProvider, {
        naturalSlideWidth: 200,
        naturalSlideHeight: 300,
        visibleSlides: 4.2,
        infinite: true,
        totalSlides: props.data.data.shops.length,
        css: shopListContainer,
        children: [_jsx(Slider, {
          style: {
            height: 'inherit'
          },
          children: props.data.data.shops.map(function (shop, index) {
            var displayBgImage = getDisplayImageObj(shop.storyImages[0], "2");
            var displayLogoImage = getDisplayImageObj(shop.logo[0], "1");

            if (!displayBgImage.url) {
              return null;
            }

            return _jsx(Slide, {
              index: index,
              children: _jsx(Link, {
                href: "/".concat(shop.slug, "?source=").concat(LaymanHomePageSectionTypes.SHOP_GROUP, "&source_name=").concat(props.data.title),
                passHref: true,
                children: _jsxs("div", {
                  css: shopContainerStyles,
                  children: [_jsxs("picture", {
                    children: [_jsx("source", {
                      srcSet: displayBgImage.webP,
                      type: "image/webp"
                    }), _jsx("img", {
                      src: displayBgImage.url,
                      css: shopBgImage
                    })]
                  }), _jsxs("div", {
                    css: shopDetailsContainer,
                    children: [_jsxs("div", {
                      className: "flex items-center",
                      children: [displayLogoImage.url ? _jsx("div", {
                        css: shopLogoContainer,
                        children: _jsxs("picture", {
                          children: [_jsx("source", {
                            srcSet: displayLogoImage.webP,
                            type: "image/webp"
                          }), _jsx("img", {
                            src: displayLogoImage.url,
                            css: shopBgImage
                          })]
                        })
                      }) : null, _jsx(Typography, {
                        type: "SubHeading1",
                        color: "white",
                        bold: true,
                        children: shop.name
                      })]
                    }), _jsx(Typography, {
                      type: "Body1",
                      color: "white",
                      className: "py-3 whitespace-normal",
                      children: shop.storyText && shop.storyText.slice(0, 200)
                    }), _jsxs(Typography, {
                      type: "Body1",
                      color: "laser_lemon",
                      semi_bold: true,
                      children: ["Shop now ", _jsx(ChevronRightRoundedIcon, {
                        fontSize: "small"
                      })]
                    })]
                  })]
                })
              }, shop.slug)
            }, shop.slug);
          })
        }), props.data.data.shops.length > 4 ? _jsxs(_Fragment, {
          children: [showPrev ? _jsx(ButtonBack, {
            onClick: onPrevClick,
            children: _jsx("div", {
              css: prevNavStyles,
              children: _jsx(ChevronLeftIcon, {})
            })
          }) : null, _jsx(ButtonNext, {
            onClick: onNextClick,
            children: _jsx("div", {
              css: nextNavStyles,
              children: _jsx(ChevronRightIcon, {})
            })
          })]
        }) : null]
      })
    })]
  });
}
export default HomepageShopGroup;