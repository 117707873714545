import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { Typography } from "@vc-workspace/utils-typography";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CollectionGroupItem from "../collection-group-item/collection-group-item";
import { collectionListContainer, lessCollectionListContainer, nextNavStyles, prevNavStyles } from './collection-group.module.style';
/* eslint-disable-next-line */

export function CollectionGroup(props) {
  var _useState = useState(false),
      showPrev = _useState[0],
      togglePrev = _useState[1];

  var onNextClick = function onNextClick() {
    togglePrev(true);
  };

  return _jsxs("div", {
    className: props.context.isMobileView ? "pt-8" : "pt-16",
    children: [_jsx(Typography, {
      type: props.context.isMobileView ? "SubHeading1" : "Headline_H2",
      color: "prussian_blue",
      className: "page-cushion-container",
      extra_bold: true,
      children: props.data.title
    }), props.context.isMobileView ? _jsx("div", {
      css: collectionListContainer,
      className: props.context.isMobileView ? "page-cushion-container--left" : "page-cushion-container",
      children: props.data.data.collections.map(function (collection, index) {
        return _jsx(CollectionGroupItem, {
          collection: collection,
          collectionListIndex: index,
          sectionTitle: props.data.title
        }, collection.usId);
      })
    }) : _jsx(_Fragment, {
      children: props.data.data.collections.length < 4 ? _jsx("div", {
        className: "page-cushion-container relative",
        children: _jsx("div", {
          css: lessCollectionListContainer,
          children: props.data.data.collections.map(function (collection, index) {
            return _jsx(CollectionGroupItem, {
              collection: collection,
              collectionListIndex: index,
              sectionTitle: props.data.title
            }, collection.usId);
          })
        })
      }) : _jsx("div", {
        className: "page-cushion-container relative",
        children: _jsxs(CarouselProvider, {
          naturalSlideWidth: 200,
          naturalSlideHeight: 300,
          visibleSlides: 3.5,
          infinite: false,
          totalSlides: props.data.data.collections.length,
          css: collectionListContainer,
          children: [_jsx(Slider, {
            style: {
              height: 'inherit'
            },
            children: props.data.data.collections.map(function (collection, index) {
              return _jsx(Slide, {
                index: index,
                children: _jsx(CollectionGroupItem, {
                  collection: collection,
                  collectionListIndex: index,
                  sectionTitle: props.data.title
                })
              }, collection.usId);
            })
          }), props.data.data.collections.length > 3 ? _jsxs(_Fragment, {
            children: [showPrev ? _jsx(ButtonBack, {
              children: _jsx("div", {
                css: prevNavStyles,
                children: _jsx(ChevronLeftIcon, {})
              })
            }) : null, _jsx(ButtonNext, {
              onClick: onNextClick,
              children: _jsx("div", {
                css: nextNavStyles,
                children: _jsx(ChevronRightIcon, {})
              })
            })]
          }) : null]
        })
      })
    })]
  });
}
export default CollectionGroup;