/** @jsxImportSource @emotion/react */

import { ReactNode, useRef } from "react";
import Slider from "react-slick";
import {sliderDotsContainer, sliderDotStyles, rightSliderDotsContainer, dotStyles} from './full-width-image-carousel.module.style';

type SliderSettingsConfig = {
  dots?: boolean,
  infinite?: boolean,
  speed?: number,
  slidesToShow?: number,
  lazyLoad?: string,
  autoplay?: boolean,
  autoplaySpeed?: number,
  accessibility?: boolean,
  fade?: boolean,
  navigation?: boolean,
}

/* eslint-disable-next-line */
export interface FullWidthImageCarouselProps {
  customSliderConfig?: SliderSettingsConfig,
  children: ReactNode,
  sliderRightNavigation?: boolean,
  whiteDots?: boolean,
  dotColor?: string
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: 'ondemand',
  autoplay: true,
  autoplaySpeed: 5000,
  accessibility: false,
  fade: false,
  navigation: false
};

export function FullWidthImageCarousel(props: FullWidthImageCarouselProps) {
  const sliderRef = useRef(null);
  

  function onSliderDotClick(position: number) {
    if(sliderRef && sliderRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sliderRef.current.slickGoTo(position)
    }
  }

  return (
    <Slider
      ref={sliderRef}
      {...{...settings, ...props.customSliderConfig}}
      appendDots={(dots: any) => (
        <div className="relative text-center">
          <ul css={props.sliderRightNavigation ? rightSliderDotsContainer : sliderDotsContainer}>
            {dots.map((dot: any, index: number) => {
              return (
                <span
                  key={dot.key} 
                  onClick={() => onSliderDotClick(index)} 
                  css={[sliderDotStyles(dot.props.className === 'slick-active', props.dotColor), props.whiteDots ? dotStyles(props.dotColor) : null]} />
              )
            })}
          </ul>
        </div>
      )}>
      {props.children}
  </Slider>
  );
}
