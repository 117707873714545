import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import _toConsumableArray from "/usr/src/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/** @jsxImportSource @emotion/react */
import Link from 'next/link';
import { LaymanHomePageSectionTypes, SourceKey, SourceNameKey } from '@vc-workspace/utils-constants';
import { Typography } from '@vc-workspace/utils-typography';
import { productListContainer, viewMoreContainer, prevNavStyles, nextNavStyles, sliderRightShadow, viewMoreImageCard, viewMoreImageCardTile, viewMoreGridContainer, patternTopLeft, patternTopRight, headerContainer, viewMoreCardCta } from './item-collection-section.module.style';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { AmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-amplitude';
import { convertToTitleCase } from '@vc-workspace/utils-formatters';
import { getDisplayImageObj } from '@vc-workspace/utils-helpers';
import { useState } from 'react';
import ProductCard from '../product-card/product-card';
/* eslint-disable-next-line */

export function ItemCollectionSection(props) {
  var _useState = useState(false),
      showPrev = _useState[0],
      togglePrev = _useState[1];

  var displaySias = _toConsumableArray(props.data.data.sias);

  var onViewAllClick = function onViewAllClick() {
    sendAmplitudeData(AmplitudeEventNames.VIEW_ALL_CLICKED, {
      source: LaymanHomePageSectionTypes.SIA_COLLECTION,
      source_name: props.data.title
    });
  };

  var onPrevClick = function onPrevClick() {
    sendAmplitudeData(AmplitudeEventNames.PREV_CLICKED, {
      source: LaymanHomePageSectionTypes.SIA_COLLECTION,
      source_name: props.data.title
    });
  };

  var onNextClick = function onNextClick() {
    togglePrev(true);
    sendAmplitudeData(AmplitudeEventNames.NEXT_CLICKED, {
      source: LaymanHomePageSectionTypes.SIA_COLLECTION,
      source_name: props.data.title
    });
  };

  var showViewMore = props.data.data.siasCount > props.data.data.sias.length;
  return _jsx("div", {
    className: props.context.isMobileView ? 'pt-8' : 'pt-16',
    children: _jsxs("div", {
      children: [_jsxs("div", {
        className: "page-cushion-container",
        css: headerContainer,
        children: [_jsx(Typography, {
          type: props.context.isMobileView ? 'SubHeading1' : 'Headline_H2',
          color: "prussian_blue",
          className: "truncate",
          extra_bold: true,
          children: convertToTitleCase(props.data.title)
        }), showViewMore ? _jsx(Link, {
          href: "/collection/".concat(props.data.slug, "/").concat(props.data.usId, "?").concat(SourceKey, "=").concat(LaymanHomePageSectionTypes.SIA_COLLECTION, "&").concat(SourceNameKey, "=").concat(props.data.title),
          passHref: true,
          children: _jsx("a", {
            onClick: onViewAllClick,
            children: _jsxs(Typography, {
              type: "Body2",
              color: "purple_heart",
              className: props.context.isMobileView ? 'flex justify-end' : 'ml-4',
              semi_bold: true,
              children: [_jsx("span", {
                className: "align-middle",
                children: "View All"
              }), _jsx(ChevronRightRoundedIcon, {
                fontSize: "small"
              })]
            })
          })
        }) : null]
      }), props.context.isMobileView ? _jsxs("div", {
        css: productListContainer,
        className: props.data.data.sias.length < props.data.data.sias.length ? 'page-cushion-container--left' : 'justify-between page-cushion-container--left',
        children: [displaySias.map(function (sia) {
          return _jsx(ProductCard, {
            keyId: sia._id,
            sia: sia,
            context: props.context,
            sourceName: props.data.title,
            sourceType: LaymanHomePageSectionTypes.SIA_COLLECTION,
            cardRowPosition: props.rowIndex + 1,
            isShallowNavigation: true
          }, sia._id);
        }), showViewMore ? _jsx(Link, {
          href: "/collection/".concat(props.data.slug, "/").concat(props.data.usId, "?").concat(SourceKey, "=").concat(LaymanHomePageSectionTypes.SIA_COLLECTION, "&").concat(SourceNameKey, "=").concat(props.data.title),
          passHref: true,
          children: _jsx("a", {
            onClick: onViewAllClick,
            children: _jsxs("div", {
              css: viewMoreContainer,
              children: [_jsxs("div", {
                css: viewMoreImageCard,
                children: [_jsx("img", {
                  src: "/_mp-images/view_more_pattern-top-left.png",
                  css: patternTopLeft
                }), _jsx("img", {
                  src: "/_mp-images/view_more_pattern-top-right.png",
                  css: patternTopRight
                }), _jsxs(Typography, {
                  type: "Body2",
                  color: "purple_heart",
                  className: "flex items-center",
                  custom_style: viewMoreCardCta,
                  semi_bold: true,
                  children: [_jsx("span", {
                    className: "align-middle",
                    children: "View All"
                  }), _jsx(ChevronRightRoundedIcon, {
                    fontSize: "small"
                  })]
                }), _jsx("div", {
                  css: viewMoreGridContainer,
                  children: props.data.data.sampleSiaImages.map(function (image, index) {
                    var displayImage = getDisplayImageObj(image, "1");
                    return _jsxs("picture", {
                      css: viewMoreImageCardTile,
                      children: [_jsx("source", {
                        srcSet: displayImage.webP,
                        type: "image/webp"
                      }), _jsx("img", {
                        src: displayImage.url
                      })]
                    }, index);
                  })
                })]
              }), _jsxs(Typography, {
                type: "Body1",
                color: "nero",
                className: "whitespace-normal pt-3",
                children: [_jsxs("b", {
                  children: [props.data.data.siasCount - props.data.data.sias.length, " more"]
                }), " products"]
              })]
            })
          })
        }) : null]
      }) : _jsxs("div", {
        className: "page-cushion-container relative",
        children: [_jsxs(CarouselProvider, {
          naturalSlideWidth: 200,
          naturalSlideHeight: 300,
          visibleSlides: 7.2,
          infinite: false,
          totalSlides: showViewMore ? displaySias.length + 1 : displaySias.length,
          css: productListContainer,
          children: [_jsxs(Slider, {
            style: {
              height: 'inherit'
            },
            children: [displaySias.map(function (sia, index) {
              return _jsx(Slide, {
                index: index,
                children: _jsx(ProductCard, {
                  sia: sia,
                  context: props.context,
                  sourceName: props.data.title,
                  sourceType: LaymanHomePageSectionTypes.SIA_COLLECTION,
                  cardRowPosition: props.rowIndex + 1
                })
              }, sia._id);
            }), showViewMore ? _jsx(Slide, {
              index: displaySias.length,
              children: _jsx(Link, {
                href: "/collection/".concat(props.data.slug, "/").concat(props.data.usId, "?").concat(SourceKey, "=").concat(LaymanHomePageSectionTypes.SIA_COLLECTION, "&").concat(SourceNameKey, "=").concat(props.data.title),
                passHref: true,
                children: _jsx("a", {
                  onClick: onViewAllClick,
                  children: _jsxs("div", {
                    css: viewMoreContainer,
                    children: [_jsxs("div", {
                      css: viewMoreImageCard,
                      children: [_jsx("img", {
                        src: "/_mp-images/view_more_pattern-top-left.png",
                        css: patternTopLeft
                      }), _jsx("img", {
                        src: "/_mp-images/view_more_pattern-top-right.png",
                        css: patternTopRight
                      }), _jsxs(Typography, {
                        type: "Body2",
                        color: "purple_heart",
                        className: "flex items-center",
                        custom_style: viewMoreCardCta,
                        semi_bold: true,
                        children: [_jsx("span", {
                          className: "align-middle",
                          children: "View All"
                        }), _jsx(ChevronRightRoundedIcon, {
                          fontSize: "small"
                        })]
                      }), _jsx("div", {
                        css: viewMoreGridContainer,
                        children: props.data.data.sampleSiaImages.map(function (image, index) {
                          var displayImage = getDisplayImageObj(image, "1");
                          return _jsxs("picture", {
                            css: viewMoreImageCardTile,
                            children: [_jsx("source", {
                              srcSet: displayImage.webP,
                              type: "image/webp"
                            }), _jsx("img", {
                              src: displayImage.url
                            })]
                          }, index);
                        })
                      })]
                    }), _jsxs(Typography, {
                      type: "Body1",
                      color: "nero",
                      className: "whitespace-normal pt-3",
                      children: [_jsxs("b", {
                        children: [props.data.data.siasCount - props.data.data.sias.length, " more"]
                      }), " products"]
                    }), _jsxs(Typography, {
                      type: "Body2",
                      color: "purple_heart",
                      className: "flex items-center pt-1",
                      semi_bold: true,
                      children: [_jsx("span", {
                        className: "align-middle",
                        children: "View All"
                      }), _jsx(ChevronRightRoundedIcon, {
                        fontSize: "small"
                      })]
                    })]
                  })
                })
              })
            }) : null]
          }), displaySias.length > 7 ? _jsxs(_Fragment, {
            children: [showPrev ? _jsx(ButtonBack, {
              onClick: onPrevClick,
              children: _jsx("div", {
                css: prevNavStyles,
                children: _jsx(ChevronLeftIcon, {})
              })
            }) : null, _jsx(ButtonNext, {
              onClick: onNextClick,
              children: _jsx("div", {
                css: nextNavStyles,
                children: _jsx(ChevronRightIcon, {})
              })
            })]
          }) : null]
        }), _jsx("div", {
          css: sliderRightShadow
        })]
      })]
    })
  });
}
export default ItemCollectionSection;