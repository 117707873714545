import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import _defineProperty from "/usr/src/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

/* eslint-disable @next/next/no-img-element */

/** @jsxImportSource @emotion/react */
import { useRouter } from 'next/router';
import { getImagesByType } from "@vc-workspace/utils-helpers";
import { FullWidthImageCarousel } from '@vc-workspace/full-width-image-carousel';
import { bannerStyle } from './homepage-banner-widget.module.style';
import { BannerWidgetDisplayType, HomePageSectionTypes, ImageAccessibility, ImageTypes, INTERNAL_NAVIGATION_UTM_MEDIUM, LaymanHomePageSectionTypes, SourceKey, VC_APP_GENERIC_DOWNLOAD_LINK } from '@vc-workspace/utils-constants';
import { AmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-amplitude';
/* eslint-disable-next-line */

export function HomepageBannerWidget(props) {
  var router = useRouter();

  function onBannerClick(bannerItem) {
    if (bannerItem.actionType !== 'REDIRECT') {
      return;
    }

    var pathname = "";

    switch (bannerItem.actionDetails.type) {
      case 'CATEGORY':
        pathname = "/category/".concat(bannerItem.actionDetails.slug);
        break;

      case HomePageSectionTypes.SIA_COLLECTION:
        pathname = "/collection/".concat(bannerItem.actionDetails.slug, "/").concat(bannerItem.actionDetails.usId);
        break;

      case 'SHOP':
        pathname = "/".concat(bannerItem.actionDetails.slug, "?utm_medium=").concat(INTERNAL_NAVIGATION_UTM_MEDIUM);
        break;

      case 'MAKERS_PAGE':
        window.location.href = "/maker?".concat(SourceKey, "=").concat(LaymanHomePageSectionTypes.BANNER_CAROUSEL);
        break;

      case 'APP_DOWNLOAD_LINK':
        if (props.context.isMobileView) {
          sendAmplitudeData(AmplitudeEventNames.DOWNLOAD_LINK_CLICKED, {
            type: 'ONELINK',
            source: LaymanHomePageSectionTypes.BANNER_CAROUSEL
          });
          window.open(VC_APP_GENERIC_DOWNLOAD_LINK, "_blank");
        }

        break;
    }

    if (pathname) {
      ;
      router.push({
        pathname: pathname,
        query: _defineProperty({}, SourceKey, LaymanHomePageSectionTypes.BANNER_CAROUSEL)
      });
    }
  }

  return _jsx("div", {
    className: props.positionIndex === 0 ? "" : props.context.isMobileView ? "pt-8" : "pt-16",
    children: _jsx("div", {
      className: props.data.data.displayType === BannerWidgetDisplayType.FULL_WIDTH ? "" : "page-cushion-container",
      children: _jsx(FullWidthImageCarousel, {
        children: props.data.data.banners.map(function (bannerItem, index) {
          var displayImage = getImagesByType(bannerItem.images, ImageTypes.Banner, "3", props.context.isMobileView ? ImageAccessibility.Mobile : ImageAccessibility.Desktop, false);
          return _jsxs("picture", {
            children: [_jsx("source", {
              type: "image/webp",
              srcSet: displayImage.webP
            }), _jsx("img", {
              src: displayImage.url,
              css: bannerStyle,
              alt: "",
              onClick: function onClick() {
                return onBannerClick(bannerItem);
              }
            })]
          }, index);
        })
      })
    })
  });
}
export default HomepageBannerWidget;