
import {css} from '@emotion/react';
import {DIMENSIONS} from '@vc-workspace/utils-dimens';
import {Colors} from '@vc-workspace/utils-colors';

export const imageStyles = css({
  width: '100%',
  height: '500px',
  objectFit: 'cover',
  '@media screen and (max-width: 768px)': {
    height: '400px',
  }
});

export const sliderDotsContainer = css({
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  left: '50%',
  top: DIMENSIONS.px_32,
  marginTop: '-50px',
  transform: 'translateX(-50%)'
})

export const rightSliderDotsContainer = css({
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  right: '0px',
  top: '-12px',
  marginTop: '-50px',
  transform: 'translateX(-50%)'
})

export const sliderDotStyles = (is_active: boolean, color?: string) => css({
  width: is_active ? DIMENSIONS.px_20 : DIMENSIONS.px_8,
  height: DIMENSIONS.px_8,
  marginRight: '6px',
  borderRadius: is_active ? DIMENSIONS.px_4 : '50%',
  background: color ? Colors[color] : Colors.dark_tangerine,
  opacity: is_active ? 1 : 0.5
})

export const dotStyles = (color?: string) => css({
  background: color ? Colors[color] : Colors.white
})