function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import { css } from '@emotion/react';
export var bannerStyle = true ? {
  name: "107cmgv",
  styles: "width:100%;&:hover{cursor:pointer;}"
} : {
  name: "wij3q9-bannerStyle",
  styles: "width:100%;&:hover{cursor:pointer;};label:bannerStyle;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi91c3Ivc3JjL2FwcC9hcHBzL21hcmtldHBsYWNlL2NvbXBvbmVudHMvaG9tZXBhZ2UtYmFubmVyLXdpZGdldC9ob21lcGFnZS1iYW5uZXItd2lkZ2V0Lm1vZHVsZS5zdHlsZS50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRzJCIiwiZmlsZSI6Ii91c3Ivc3JjL2FwcC9hcHBzL21hcmtldHBsYWNlL2NvbXBvbmVudHMvaG9tZXBhZ2UtYmFubmVyLXdpZGdldC9ob21lcGFnZS1iYW5uZXItd2lkZ2V0Lm1vZHVsZS5zdHlsZS50c3giLCJzb3VyY2VzQ29udGVudCI6WyJcbmltcG9ydCB7Y3NzfSBmcm9tICdAZW1vdGlvbi9yZWFjdCc7XG5cbmV4cG9ydCBjb25zdCBiYW5uZXJTdHlsZSA9IGNzcyh7XG4gIHdpZHRoOiAnMTAwJScsXG4gICcmOmhvdmVyJzoge1xuICAgIGN1cnNvcjogJ3BvaW50ZXInXG4gIH1cbn0pIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};